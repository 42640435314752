import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);

Vue.config.productionTip = false
import locale from 'element-ui/lib/locale/lang/zh-CN'


Vue.use(ElementUI, { locale });

new Vue({
  render: h => h(App)
}).$mount('#app')
