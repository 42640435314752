<template>
  <div class="title-bar">
    <img class="logo" src="@/assets/logo.png" fit="" />

    <div class="search">
      <el-input
        placeholder="请输入关键字 按回车 / Enter 搜索"
        v-model.trim="keywords"
        clearable
        @change="toSearch"
      >
        <el-select v-model="select" slot="prepend" placeholder="请选择">
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in categoryList"
            :key="item.id"
          ></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search">搜索</el-button>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keywords: "",
      select: "1",
      categoryList: [
        {
          id: "1",
          name: "百度",
          url: "https://www.baidu.com/s?ie=utf-8&word=",
          iconfont: "https://www.baidu.com/favicon.ico",
        },
        {
          id: "2",
          name: "必应",
          url: "https://cn.bing.com/search?q=",
          iconfont: "https://cn.bing.com/sa/simg/favicon-trans-bg-blue-mg.ico",
        },
        {
          id: "3",
          name: "谷歌",
          url: "https://www.google.com/search?q=",
          iconfont: "https://chrome.hongysz.com/favicon.ico",
        },
        {
          id: "4",
          name: "F 搜",
          url: "https://fsoufsou.com/search?q=",
          iconfont:
            "https://static.hetaousercontent.com/static/assets/favicon.ico",
        },
        {
          id: "5",
          name: "搜狗",
          url: "https://www.sogou.com/web?query=",
          iconfont: "https://www.sogou.com/images/logo/new/favicon.ico?v=4",
        },
        {
          id: "6",
          name: "好搜",
          url: "https://www.so.com/s?q=",
          iconfont: "https://ss.360tres.com/static/121a1737750aa53d.ico",
        },
      ],
    };
  },
  methods: {
    toSearch() {
      if (this.keywords != "") {
        this.categoryList.some((item) => {
          if (item.id === this.select) {
            window.open(item.url + this.keywords);
            return true;
          }
        });
      }

      this.keywords = "";
    },
  },
};
</script>

<style lang="less" scoped>
.title-bar {
  display: flex;
  width: 100%;
  .logo {
    width: 220px;
    height: 80px;
    margin-right: 80px;
  }
  .search {
    flex: 1;
    text-align: left;
    .el-input {
      width: 60%;
      .el-select {
        width: 80px;
        height: 48px;
        line-height: 48px;
        text-align: center;
      }
      /deep/.el-input__inner {
        border: 0;
        height: 48px;
        line-height: 48px;
      }
      /deep/.el-select__caret {
        font-size: 16px;
      }
      /deep/.el-select__icon {
        line-height: 48px;
      }
      /deep/.el-input-group__prepend {
        background-color: #fff;
        border: 0;
        height: 48px;
        line-height: 48px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      /deep/.el-input-group__append {
        background-color: #69bdfb;
        width: 100px;
        text-align: center;
        color: #fff;
        border: 0;
        height: 48px;
        line-height: 48px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}
</style>
