<template>
  <div id="app">
    <el-container>
      <el-header height="80px">
        <TitleBar></TitleBar>
      </el-header>
      <el-container>
        <el-aside width="12%">
          <SiderBar></SiderBar>
        </el-aside>
        <el-container>
          <el-main>
            <ContentLayout></ContentLayout>
          </el-main>
          <el-footer height="48px">
            <div @click="goThomas">
              Copyright&nbsp;©&nbsp;{{
                currentYear
              }}&nbsp;&nbsp;&nbsp;&nbsp;闲话非说
            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
    <el-card :body-style="{ padding: '0px' }">
      <i class="el-icon-top" @click="moveTop"></i>
      <el-divider></el-divider>
      <i class="el-icon-chat-dot-round" @click="showContract"></i>
      <el-divider></el-divider>
      <i class="el-icon-edit-outline" @click="toFeedback"></i>
    </el-card>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import SiderBar from "@/components/SiderBar.vue";
import ContentLayout from "@/components/ContentLayout.vue";
export default {
  name: "App",
  components: {
    TitleBar,
    SiderBar,
    ContentLayout,
  },
  data() {
    return {
      currentYear: "",
   
    };
  },

  methods: {
    getCurrentYear() {
      this.currentYear = new Date().getFullYear();
    },
    goThomas() {
      window.open("https://www.baidu.com");
    },
    toFeedback(){
      window.open("https://support.qq.com/product/451905");
    },
    moveTop(){
    
      window.scrollTo(0,0)
    },
    showContract(){
      console.log('展示联系人二维码');
    },

  },

  created() {
    this.getCurrentYear();
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f5f5;
  padding: 0;
  margin: 0;
}

.el-header {
  background-color: #2eb2ff;
  text-align: center;
  line-height: 80px;
  position: fixed;
  width: 100%;
  z-index: 1500;
}

.el-container {
  padding: 0;
  margin: 0;
}

.el-aside {
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: 0;
  height: 100vh;
  position: fixed;
  top: 80px;
  z-index: 1;
}

.el-main {
  background-color: #f5f5f5;
  color: #333;
  padding-top: 80px;
  padding-bottom: 48px;
  margin-left: 14%;
  margin-right: 14%;
}

.el-footer {
  width: 100%;
  position: fixed;
  background-color: #f5f5f5;
  bottom: 0;
  padding: 0;
  line-height: 48px;
  font-size: 12px;
  text-align: center;
  div {
    cursor: pointer;
  }
}

.el-card {
  position: fixed;
  width: 48px;
  height: 146px;
  right: 32px;
  bottom: 80px;
  .el-divider{
    margin: 0;
    padding: 0;
    height: 1px;
    background-color: #f5f5f5;
  }
  i {
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
