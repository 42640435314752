<template>
  <div>
    <el-menu :default-active="active" @select="menuSelected" active-text-color="#303133">
      <el-menu-item :index="item.key" v-for="item in menus" :key="item.key" >
        {{ item.value }}
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
import bus from "@/components/eventBus";

export default {

  data() {
    return {
      active:"0",
      menus: [
        {
          key: "0",
          value: "常用推荐",
        },
        {
          key: "1",
          value: "软件开发",
        },
        {
          key: "2",
          value: "平面设计",
        },
        {
          key: "3",
          value: "产品经理",
        },
        {
          key: "4",
          value: "生活百态",
        },
        {
          key: "5",
          value: "副业搞钱",
        },
        {
          key: "6",
          value: "平台工具",
        },
        {
          key: "7",
          value: "极客资源",
        },
        {
          key: "8",
          value: "成长之路",
        },
      ],
    };
  },
  methods: {
    menuSelected(index) {
      bus.$emit("menu-active", this.menus[index]);
    },
  },
  mounted() {
    this.menuSelected(this.active);
    bus.$emit("menus", this.menus);
  },
};
</script>

<style lang="less" scoped>
.el-menu {
  border: 0;
  .el-menu-item {
    font-weight: bolder;
    font-size: 16px;
  }
}
</style>
